import React from 'react';
import PropTypes from 'prop-types';

import './SpanLink.scss';
import { Paragraph, Title } from '../Typography';

const SpanLink = ({ label, handleClick, id, active, children, component, dot }) => {
  let content;
  switch (component) {
    case 'paragraph':
      content = <Paragraph>{ children }</Paragraph>
      break;
    case 'title':
      content = <Title dot={dot}>{ children }</Title>
      break;
    default:
      content = <div>{ children }</div>
  }
  return (
    <span
      tabIndex={0}
      role="link"
      aria-label={`scroll to section ${label}`}
      className={`span-link ${active ? 'active' : ''}`}
      onClick={() => handleClick(id)}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          handleClick(id);
        }
      }}
      >
      { content }
    </span>

  )
};

SpanLink.propTypes = {
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
};

SpanLink.defaultProps = {
  component: '',
  active: false,
};

export default SpanLink;
