import React from 'react'
import PropTypes from 'prop-types'

import './Typography.scss';

const Exclaim = () => (
  <svg className="svg-exclaim" viewBox="0 0 7 25">
      <g fillRule="nonzero" fill="none">
        <path fill="#32323A" d="M5.833 16L7 0H0l1.167 16z" />
        <path
          d="M3.5 25c.997 0 1.829-.344 2.497-1.03C6.666 23.281 7 22.445 7 21.46c0-.985-.328-1.808-.984-2.47C5.359 18.332 4.52 18 3.5 18c-1.02 0-1.86.33-2.516.992-.656.66-.984 1.484-.984 2.47 0 .984.334 1.82 1.003 2.507C1.67 24.656 2.503 25 3.5 25z"
          fill="#E9A028"
        />
      </g>
  </svg>
);

const Ask = () => (
  <svg className="svg-ask" viewBox="0 0 19 27">
    <g fillRule="nonzero" fill="none">
      <path
        d="M13.02 17.304c0-.77.2-1.426.601-1.968.401-.542 1.038-1.21 1.911-2.004a26.02 26.02 0 001.787-1.788c.46-.517.849-1.15 1.167-1.895.319-.747.478-1.613.478-2.6 0-2.095-.82-3.732-2.459-4.912C14.865.957 12.69.368 9.977.368c-2.312 0-4.31.421-5.997 1.264C2.293 2.474.955 3.666-.036 5.207L4.245 7.77a6.34 6.34 0 012.176-1.968c.861-.47 1.846-.704 2.955-.704 1.155 0 2.081.258 2.777.776.696.518 1.044 1.222 1.044 2.112 0 .626-.183 1.186-.549 1.68-.365.493-.937 1.101-1.716 1.823-1.061.987-1.869 1.89-2.423 2.709-.555.818-.832 1.853-.832 3.105h5.343z"
        fill="#32323A"
      />
      <path
        d="M10.366 26.368c.967 0 1.775-.319 2.424-.957a3.144 3.144 0 00.973-2.33c0-.914-.319-1.678-.955-2.292-.637-.614-1.451-.921-2.442-.921-.99 0-1.804.307-2.441.92-.637.615-.955 1.38-.955 2.294 0 .915.324 1.691.973 2.33.648.637 1.456.956 2.423.956z"
        fill="#E9A028"
      />
    </g>
  </svg>
);

export const Heading = ({ children, className, exclaim, dot, ask }) => (
  <h1 className={`heading ${className}`}>
    { children }
    { exclaim && <Exclaim /> }
    { ask && <Ask /> }
    { dot && <span className="text-alcesys">.</span>}
  </h1>
);

export const Title = ({ children, className, exclaim, dot, ask }) => (
  <h1 className={`title ${className}`}>
    { children }
    { exclaim && <Exclaim /> }
    { ask && <Ask /> }
    { dot && <span className="text-alcesys">.</span>}
  </h1>
);

export const FloatingTitle = ({ children, className }) => (
  <h1 className={`floating-title ${className}`}>{ children }</h1>
);

export const Paragraph = ({ children, className }) => (
  <p className={`paragraph ${className}`}>{ children }</p>
);

export const ParagraphBlock = ({ children, className }) => (
  <div className={`paragraph-block ${className}`}>{ children }</div>
);

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dot: PropTypes.bool,
  exclaim: PropTypes.bool,
};

Heading.defaultProps = {
  className: '',
  exclaim: false,
  dot: true,
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dot: PropTypes.bool,
  exclaim: PropTypes.bool,
};

Title.defaultProps = {
  className: '',
  exclaim: false,
  dot: true,
};

FloatingTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FloatingTitle.defaultProps = {
  className: '',
};

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Paragraph.defaultProps = {
  className: '',
};

ParagraphBlock.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ParagraphBlock.defaultProps = {
  className: '',
};
