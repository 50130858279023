import React  from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from '../Header';
import Footer from '../Footer';
import './Layout.scss';

const Layout = ({ children, topSectionHeight, isSmallScreen }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="layout">
      <Header topSectionHeight={topSectionHeight} siteTitle={data.site.siteMetadata.title} isSmallScreen={isSmallScreen} />
      <div className="page-wrapper">
        <main>
          {children}
        </main>
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  topSectionHeight: PropTypes.number.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

export default Layout;
