import React from 'react';
import PropTypes from 'prop-types';

import './IconButton.scss';

const IconButton = ({
  disabled,
  children,
  handleClick,
  isLoading,
  small,
  tabIndex,
}) => (
  <button
    className="icon-button"
    disabled={disabled || isLoading}
    onClick={handleClick}
    tabIndex={tabIndex}
  >
    <div className={`icon-button-inner ${small ? 'btn-sm' : 'btn-size'}`}>
      { children }
    </div>
  </button>
);
IconButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  tabIndex: PropTypes.number,
};

IconButton.defaultProps = {
  disabled: false,
  small: false,
  isLoading: false,
  tabIndex: 0,
};

export default IconButton;
