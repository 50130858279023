import React from 'react';
import { useStaticQuery } from 'gatsby';

import './Footer.scss';
import Grid from '../Grid';
import { ParagraphBlock } from '../Typography';
import LinkedInIcon from './linkedin.svg';
import MailIcon from './mail.svg';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { path: { eq: "/content/footer-contact" } } }) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              path
            }
          }
        }
      }
    }
  `);

  const nodes = data
    ? data.allMarkdownRemark.edges.map(e => e.node)
    : [];

  const blockContent = nodes.length > 0
    ? nodes.find(n => n.frontmatter.path === '/content/footer-contact')
    : { html: '', frontmatter: { title: '' } };

  return (
    <div className="foot-wrap">
      <footer className="footer text-light">
        <Grid row classes="content-wrapper">
          <Grid xs={12} sm={4} md={3}>
            <div className="content">
              <ParagraphBlock>
                <div dangerouslySetInnerHTML={{ __html: blockContent.html}} />
              </ParagraphBlock>
              <div className="divider" />
              <div className="footer-links">
                <div className="flex flex-center">
                  <a href="https://www.linkedin.com/company/alcesys-ab/" target="_blank" rel="noreferrer noopener" aria-describedby="Link to Alcesys LinkedIn">
                    <img src={LinkedInIcon} alt="LinkedIn icon" />
                  </a>
                </div>
                <div className="flex flex-center">
                  <a href="mailto:kontaktaoss@alcesys.se" rel="noreferrer noopener" aria-describedby="Link to Alcesys mail">
                    <img src={MailIcon} alt="Facebook icon" />
                  </a>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </footer>
    </div>
  );
}
Footer.propTypes = {

};

export default Footer;
