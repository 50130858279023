import React, { useState, useContext } from "react"
import PropTypes from "prop-types"

import { AppContext } from '../../context/AppContext';
import './Header.scss';
import useScrollPosition from '../../hooks/useScrollPosition';
import Navigation from '../Navigation';
import Grid from '../Grid';
import SpanLink from '../SpanLink';
import Logo from './Logo';

const TRIGGER_TEXT_DARK = 5;

const Header = ({ siteTitle, topSectionHeight, isSmallScreen }) => {
  const [visibilityClass, setVisibilityClass] = useState('visible');
  const [classes, setClasses] = useState({
    text: 'text-light',
    fill: 'fill-white',
    icon: 'icon-white',
  });
  const [{ sectionTitle, navigate }, setState] = useContext(AppContext);

  useScrollPosition(
    ({ prevPos, currPos: { y, offsetTop } }) => {
      const isWithinTopBlock = offsetTop < topSectionHeight;
      if (offsetTop < 100 && sectionTitle !== 'Hem') {
        setState(state => ({ ...state, sectionTitle: 'Hem', activeMenuItem: '' }));
        const activeEl = document.activeElement;
        if (activeEl.classList.contains('span-link')) {
          activeEl.blur();
        }
      }
      if (offsetTop <= TRIGGER_TEXT_DARK) {
        const currColorClasses = {
          text: 'text-light',
          fill: 'fill-white',
          icon: 'icon-white',
        };
        if (currColorClasses.text !== classes.text){
          setClasses(currColorClasses);
        }
      }
      if (offsetTop > TRIGGER_TEXT_DARK && classes.text === 'text-light') {
        const currColorClasses = {
          text: 'text-dark',
          fill: 'fill-black',
          icon: 'icon-dark',
        };
        if (currColorClasses.text !== classes.text) {
          setClasses(currColorClasses);
        }
      }
      if (isSmallScreen && !isWithinTopBlock) {
        const isVisible = y > prevPos.y;
        const currentClass = isVisible ? 'visible' : 'not-visible';
        if (currentClass === visibilityClass) return;
        setVisibilityClass(currentClass);
      }
    },
    [visibilityClass, topSectionHeight, sectionTitle, classes.text]
  );
  
  return (
    <header className={`header ${visibilityClass} ${classes.text}`}>
      <Grid xs={6} sm={4}>
        <SpanLink
          handleClick={navigate}
          id="top"
          label={siteTitle}
          active={false}
          >
          <Logo fontFill={classes.fill} />
        </SpanLink>
      </Grid>
      <Grid xs={6} md={8}>
        <Grid row classes="justify-end">
          <Navigation iconColor={classes.icon} isSmallScreen={isSmallScreen} />
        </Grid>
      </Grid>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  topSectionHeight: PropTypes.number.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
