import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import './Navigation.scss';
import SpanLink from '../SpanLink';
import IconButton from '../IconButton';
import { AppContext } from '../../context/AppContext';

const Navigation = ({ iconColor, isSmallScreen }) => {
  const [{
    activeMenuItem,
    menuItems,
    navigate,
    toggleMenu,
    menuOpen,
  }] = useContext(AppContext);

  useEffect(() => {
    if (menuOpen && !isSmallScreen) {
      toggleMenu();
    }
  }, [isSmallScreen]); // eslint-disable-line react-hooks/exhaustive-deps

  const onNavigate = id => {
    navigate(id);
    toggleMenu();
  };

  const onToggleMenu = () => {
    document.activeElement.blur();
    toggleMenu();
  };

  return (
    <nav className="navigation">
      <div className="menu-toggle">
        <IconButton tabIndex={0} handleClick={onToggleMenu} >
          <div className={`nav-icon ${iconColor} ${menuOpen ? 'icon-close' : 'icon-open'}`} />
        </IconButton>
      </div>
      <div className={`menu-xs ${menuOpen ? 'menu-show' : 'menu-hide'}`}>
        { menuItems.map(i => (
          <SpanLink
            key={i.id}
            handleClick={onNavigate}
            label={i.label}
            id={i.id}
            active={activeMenuItem === i.activeId}
            component="title"
            dot={activeMenuItem === i.activeId}
            >{ i.label }</SpanLink>
        ))}
      </div>
      <div className="menu">
        { menuItems.map(i => (
          <SpanLink
            key={i.id}
            handleClick={navigate}
            label={i.label}
            id={i.id}
            active={activeMenuItem === i.activeId}
            component="paragraph"
            >{ i.label }</SpanLink>
        ))}
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default Navigation
